import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Thumbnail from '../../components/thumbnail';
import Content from '../../components/content';
import aboutData from '../../data/about-me';

const AboutMe = ({ type }) => {
	return (
		<div className={type !== 'page' ? 'section-padding section' : null}>
			<Container>
				<Row className={'align-items-center'}>
					<Col lg={6}>
						<Thumbnail
							classes={'about-thumbnail mb-md-30 mb-sm-30 mb-xs-30'}
							thumb={`about/${aboutData.thumb}`}
						/>
					</Col>

					<Col lg={6}>
						<Content classes={'about-content'}>
							{type !== 'page' ? (
								<h3 className="block-title">ABOUT ME</h3>
							) : null}
							<ul className="experience">
								<li>
									<h4>WORK EXPERIENCE</h4>
									<ul>
										{aboutData.workExperience.map((experience) => (
											<li
												style={{ marginTop: '15px' }}
												key={experience.id}
											>
												<strong>{experience.title}</strong> -{' '}
												{experience.description}
												<br />
												{experience.date}
											</li>
										))}
									</ul>
								</li>
								<li style={{ marginTop: '20px' }}>
									<h4>ACADEMIC BACKGROUND</h4>
									<ul>
										{aboutData.academicBackground.map((background) => (
											<li
												style={{ marginTop: '15px' }}
												key={background.id}
											>
												<strong>{background.title}</strong> -{' '}
												{background.description}
												<br />
												{background.date}
											</li>
										))}
									</ul>
								</li>
							</ul>
							<ul className="personal-info">
								<li style={{ marginTop: '30px' }}>
									<span>Languages:</span>
									{aboutData.languages.map((language, index) => (
										<p
											key={index}
											style={{ display: 'inline-block' }}
										>{`${language}`}</p>
									))}
								</li>
								<li style={{ marginTop: '20px' }}>
									<span>Linktree:</span>
									<a
										href={aboutData.linktree}
										target="_blank"
										rel="noopener noreferrer"
									>
										linktr.ee/mariannaperdinci
									</a>
								</li>
							</ul>
						</Content>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default AboutMe;
